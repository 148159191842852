#big-heart-background {
    background: url("/src/img/bigHeart.png") no-repeat 95% 10%;
    background-size: 15%;
    opacity: 0;
}

#medium-heart-background {
    background: url("/src/img/mediumHeart.png") no-repeat 82% 50%;
    background-size: 10%;
    opacity: 0;
}

#small-heart-background {
    background: url("/src/img/smallHeart.png") no-repeat 98% 60%;
    background-size: 7%;
    opacity: 0;
}

.fadeIn {
    animation: fadeIn 1.5s normal forwards;
}

.delay-1 {
    animation-delay: 1s;
}

.delay-2 {
    animation-delay: 2s;
}

.delay-3 {
    animation-delay: 3s;
}

@media (max-width: 768px) {
    #big-heart-background {
        background: url("/src/img/bigHeart.png") no-repeat 100% 25%;
        background-size: 15%;
    }

    #medium-heart-background {
        background: url("/src/img/mediumHeart.png") no-repeat 85% 50%;
        background-size: 10%;
    }

    #small-heart-background {
        background: url("/src/img/smallHeart.png") no-repeat 100% 60%;
        background-size: 7%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@media (max-height: 900px) and (max-width: 1200px) and (min-height: 700px) and (min-width: 1000px){
    #greeting {
        margin-top: 0!important;
    }
}

@media (max-height: 900px) and (min-width: 1100px) and (min-height: 600px) and (min-width: 800px) {
    /*.scale-greeting {*/
    /*    transform: scale(0.9)!important;*/
    /*}*/
    #shortInfoLink {
        margin-top: 2%!important;
    }
    #big-heart-background {
        background: url("/src/img/bigHeart.png") no-repeat 90% 25%;
        background-size: 15%;
    }

    #medium-heart-background {
        background: url("/src/img/mediumHeart.png") no-repeat 75% 55%;
        background-size: 10%;
    }

    #small-heart-background {
        background: url("/src/img/smallHeart.png") no-repeat 95% 60%;
        background-size: 7%;
    }
}

@media (max-height: 800px) and (min-width: 1100px) and (min-height: 600px) and (min-width: 800px) {
    .scale-greeting {
        transform: scale(0.9)!important;
    }
    #shortInfo {
        margin-top: 0!important;
    }
    #greeting {
        margin-top: 0!important;
    }
    #shortInfoLink {
        margin-top: 2%!important;
    }
    #big-heart-background {
        background: url("/src/img/bigHeart.png") no-repeat 90% 25%;
        background-size: 15%;
    }

    #medium-heart-background {
        background: url("/src/img/mediumHeart.png") no-repeat 75% 55%;
        background-size: 10%;
    }

    #small-heart-background {
        background: url("/src/img/smallHeart.png") no-repeat 95% 60%;
        background-size: 7%;
    }
}
