body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ReenieBeanie";
  src: url("/public/fonts/ReenieBeanie-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Mono', monospace;
  src: url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap");
}

@font-face {
  font-family: "Manrope";
  src: url("../public/fonts/Manrope-Regular.ttf"),
  url("../public/fonts/Manrope-Regular.ttf"),
  url("../public/fonts/Manrope-Bold.ttf"),
  url("../public/fonts/Manrope-Light.ttf") format("truetype");
}

@layer base {
  html {
    font-family: "Manrope", sans-serif;
    font-weight: 400;
  }
}
